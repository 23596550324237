import { REQUEST_BASE_ROUTE } from '@experiences/constants';
import type { IPagination } from '@experiences/interfaces';
import type {
    IDropdownOption,
    ISortModel,
    ISuggestValue,
} from '@uipath/portal-shell-types/components/angular-elements';
import {
    pack,
    unpack,
} from 'jsonpack';
import {
    compressToBase64,
    decompressFromBase64,
} from 'lz-string';

export interface IFilterOption extends Partial<IDropdownOption>, Partial<ISuggestValue> {
    data: any;
}

export type GridFiltersState = Record<string, IFilterOption>;

export interface IOrchestratorPagination extends Partial<IPagination> {
    filterObject?: GridFiltersState;
}

export interface IEncodedParams {
    sort?: Omit<ISortModel<{}>, 'title'>;
    filters?: GridFiltersState;
}

export const isPathExactly = (route: string): boolean => {
    const portalBaseRoute = process.buildConfigs.portalBaseRoute;
    return window.location.pathname.endsWith(`${portalBaseRoute}${route}`);
};

export const isPathIncluding = (route: string): boolean => {
    const portalBaseRoute = process.buildConfigs.portalBaseRoute;
    return window.location.pathname.includes(`${portalBaseRoute}${route}`);
};

export const getBasePath = (stripPortalPath = false) => {
    const firstPath = window.location.pathname.split('/')[1];
    const accountName = firstPath !== 'portal_' && firstPath !== '' ? `/${firstPath}` : '';

    return `${accountName}${stripPortalPath ? '' : REQUEST_BASE_ROUTE}`;
};

export const encodeUriParams = <T extends string | object>(params: T) =>
    encodeURI(compressToBase64(JSON.stringify(params)));

export const decodeUriParams = <T>(compressed: string, reviver?: (key: string, value: any) => any) =>
    JSON.parse(decompressFromBase64(decodeURIComponent(compressed)), reviver) as T;

export const encodeParams = <T extends string | object>(params: T) =>
    compressToBase64(pack(params));

export const decodeParams = <T>(compressed: string) =>
    unpack<T>(decompressFromBase64(compressed)!);

export function encodeState({
    searchTerm, filterObject, skip, top, sortBy, sortOrder,
}: IOrchestratorPagination) {
    const params: { [key: string]: string } = {};

    if (skip) {
        params.index = skip.toString();
    }

    if (top) {
        params.size = top.toString();
    }

    if (searchTerm) {
        params.search = searchTerm;
    }

    const encodedState: IEncodedParams = {};

    if (filterObject && Object.keys(filterObject).length) {
        encodedState.filters = filterObject;
    }

    if (sortBy || sortOrder) {
        encodedState.sort = {} as Omit<ISortModel<{}>, 'title'>;

        if (sortBy) {
            encodedState.sort.field = sortBy;
        }

        if (sortOrder) {
            encodedState.sort.direction = sortOrder;
        }
    }

    if (Object.keys(encodedState).length) {
        params.state = encodeParams(encodedState);
    }

    return new URLSearchParams(params);
}
